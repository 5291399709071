import React from 'react';
import {Route, Switch} from "../routing";
import HomeScreen from "../screens/HomeScreen";
import BooksScreen from "../screens/BooksScreen";
import LoginScreen from "../screens/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen";
import BookDetailsScreen from "../screens/BookDetailsScreen";
import AudioPlayerScreen from "../screens/AudioPlayerScreen";
import PageScreen from "../screens/PageScreen";
import CartScreen from "../screens/CartScreen";
import SubscriptionOrderScreen from "../screens/SubscriptionOrderScreen";
import CartCheckoutScreen from "../screens/CartCheckoutScreen";
import EmailConfirmedScreen from "../screens/EmailConfirmedScreen";
import CartPurchaseSuccessScreen from "../screens/CartPurchaseSuccessScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import SubscriptionPurchaseSuccessScreen from "../screens/SubscriptionPurchaseSuccessScreen";
import {CreditCardPaymentScreen} from "../screens/CreditCardPaymentScreen";
import CardChangeCompleteScreen from "../screens/CardChangeCompleteScreen";
import {UserDetailsScreen} from "../screens/UserDetailsScreen";
import {PageWrapper} from "./PageWrapper";
import GiftCardScreen from "../screens/GiftCardScreen";
import GiftCardCheckoutScreen from "../screens/GiftCardCheckoutScreen";
import GiftCardPurchaseSuccessScreen from "../screens/GiftCardPurchaseSuccessScreen";
import BookIsbnRedirectScreen from "../screens/BookIsbnRedirectScreen";
import {SubscriptionChangeScreen} from "../screens/SubscriptionChangeScreen";

export default function Screens() {
    return (
        <Switch>
            <PageWrapper>
                <Route exact path="/" component={HomeScreen}/>
                <Route exact path="/raamatud/kategooria/:slug+" component={BooksScreen}/>
                <Route exact path="/raamatud/isbn/:isbn" component={BookIsbnRedirectScreen}/>
                <Route exact path="/raamatud/:slug" component={BookDetailsScreen}/>
                <Route exact path="/raamatud" component={BooksScreen}/>
                <Route path="/login" component={LoginScreen}/>
                <Route path="/registreerumine" component={RegisterScreen}/>
                <Route path="/paketiost" component={SubscriptionOrderScreen}/>
                <Route path="/paketivahetus/:token" component={SubscriptionChangeScreen}/>
                <Route path="/kaardimakse/:endpoint/:token" component={CreditCardPaymentScreen}/>
                <Route path="/kuula/:id" component={AudioPlayerScreen}/>
                <Route path="/ostukorv" component={CartScreen}/>
                <Route path="/maksmine" component={CartCheckoutScreen}/>
                <Route path="/verify-email" component={EmailConfirmedScreen}/>
                <Route path="/makse-sooritatud" component={CartPurchaseSuccessScreen}/>
                <Route path="/book-payment-complete" component={CartPurchaseSuccessScreen}/>
                <Route path="/card-change-complete" component={CardChangeCompleteScreen}/>
                <Route path="/paketimakse-sooritatud" component={SubscriptionPurchaseSuccessScreen}/>
                <Route path="/subscription-payment-complete" component={SubscriptionPurchaseSuccessScreen}/>
                <Route path="/unustasid-parooli" component={ForgotPasswordScreen}/>
                <Route path="/reset-password/:token" component={ResetPasswordScreen}/>
                <Route path="/konto" component={UserDetailsScreen}/>
                <Route path="/kingitus" component={GiftCardScreen}/>
                <Route path="/kingituse-maksmine/:duration" component={GiftCardCheckoutScreen}/>
                <Route path="/kingituse-makse-sooritatud" component={GiftCardPurchaseSuccessScreen}/>
                <Route path="/:slug" component={PageScreen}/>
            </PageWrapper>
        </Switch>
    )
}
