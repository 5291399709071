export const selectSubscriberDiscount = rootState => rootState.config?.subscriberDiscount ?? 0;
export const selectDlUrl = rootState => rootState.config?.dlUrl ?? "localhost";
export const selectSentryConfig = rootState => {
  if (!rootState?.config) {
    return undefined;
  }
  return {
    sentryDsn: rootState.config.sentryDsn,
    sentryEnvironment: rootState.config.sentryEnvironment,
  };
};
