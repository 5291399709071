import axios from "axios";
import {EMPTY_CART} from "../cart/cartActions";

export const SWITCH_THEME = 'SWITCH_THEME'
export const switchTheme = BaseTheme => {
    return dispatch => {
        dispatch({
            type: SWITCH_THEME,
            baseTheme: BaseTheme
        })
    }
}

export const ADD_FETCHED_BLOCKS = 'ADD_FETCHED_BLOCKS'
export const fetchBlocks = () => {
    return (dispatch) => {
        return axios.get(process.env.API2_URL + "/sections")
          .then(response => {
              return response.data;
          })
          .then(data => {
              dispatch({
                  type: ADD_FETCHED_BLOCKS,
                  payload: Object.fromEntries(data.map(section => [section.identifier, section])),
              });
          })
          .catch(error => {
                throw (error)
            })
    }
}

export const ADD_FETCHED_PRODUCTS = 'ADD_FETCHED_PRODUCTS'
export const fetchProducts = () => {
    return (dispatch) => {
        return axios.get(`${process.env.API2_URL}/books`)
            .then(response => {
                return response.data
            })
            .then(data => {
                dispatch({
                    type: ADD_FETCHED_PRODUCTS,
                    payload: data.map(book => ({
                        ...book,
                        author: `${book.authorFirstName}  ${book.authorLastName}`,
                        reader: `${book.readerFirstName} ${book.readerLastName}`,
                    })),
                })
            })
            .catch(error => {
                throw (error)
            })
    }
}

export const ADD_FETCHED_CATEGORIES = 'ADD_FETCHED_CATEGORIES'
export const fetchCategories = () => {
    return (dispatch) => {
        return axios.get(process.env.API2_URL + "/categories")
          .then(response => {
              return Object.fromEntries(response.data.map(category => [category.id, category]));
          })
          .then(data => {
              dispatch({
                  type: ADD_FETCHED_CATEGORIES,
                  payload: data,
              });
          })
          .catch(error => {
                throw (error)
            })
    }
}

export const ADD_FETCHED_PAGES = 'ADD_FETCHED_PAGES'
export const fetchPages = () => {
    return (dispatch) => {
        return axios.get(process.env.API2_URL + '/pages')
            .then(response => {
                return response.data
            })
            .then(data => {
                dispatch({
                    type: ADD_FETCHED_PAGES,
                    payload: data
                })
            })
            .catch(error => {
                throw (error)
            })
    }
}

export const SET_USER_DATA = 'SET_USER_DATA'

export const SAVE_LIBRARY = 'SAVE_LIBRARY'
export const LIBRARY_FETCH_START = 'LIBRARY_FETCH_START'
export const LIBRARY_FETCH_COMPLETE = 'LIBRARY_FETCH_COMPLETE'
export const fetchLibrary = user => dispatch => {
    dispatch({type: LIBRARY_FETCH_START})
    axios.get(process.env.API2_URL + '/library')
        .then(response => {
            if (response.status !== 200) {
                dispatch({
                    type: EMPTY_CART,
                });
                dispatch({
                    type: SET_USER_DATA,
                    payload: null,
                });
                history.push("/");
                return undefined;
            }
            return response.data
        })
        .then(data => {
            if (data !== undefined) {
                dispatch({
                    type: SAVE_LIBRARY,
                    payload: data
                })
            }
            dispatch({type: LIBRARY_FETCH_COMPLETE})
        })
        .catch(error => {
            throw (error)
        })
}
export const addBookToLibrary = (book, user) => dispatch => {
    return axios.post(process.env.API2_URL + "/library", {bookId: book.id})
      .then(response => {
          const updatedUser = {...user};
          dispatch({
              type: SET_USER_DATA,
              payload: updatedUser,
          });
          return response;
      })
      .catch(error => {
          throw (error);
      });
}

export const PLAYER_OPEN = 'PLAYER_OPEN'
export const PLAYER_CLOSE = 'PLAYER_CLOSE'
export const PLAYER_SET_VOLUME = 'PLAYER_SET_VOLUME'
export const PLAYER_SET_RATE = 'PLAYER_SET_RATE'
export const PLAYER_SET_INDEX = 'PLAYER_SET_INDEX'
export const PLAYER_SET_POSITION = 'PLAYER_SET_POSITION'

export const MENU_OPEN = 'MENU_OPEN'
export const MENU_CLOSE = 'MENU_CLOSE'

export const SET_CLOSED_ANNOUNCEMENTS = "SET_CLOSED_ANNOUNCEMENTS";

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const setSearchQuery = value => dispatch => {
    dispatch({
        type: SET_SEARCH_QUERY,
        payload: value
    })
}

export const SET_GDPR_CONSENT = 'SET_GDPR_CONSENT';
export const setGdprConsent = value => dispatch => {
    dispatch({
        type: SET_GDPR_CONSENT,
        payload: value
    });
};

export const ADD_PATH_TO_HISTORY = 'ADD_PATH_TO_HISTORY';

export const addPathToHistory = value => dispatch => {
    dispatch({
        type: ADD_PATH_TO_HISTORY,
        payload: value
    })
}